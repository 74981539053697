//Download Starbites Section For Desktop

import ParallaxSection from '../helpers/ParallaxComponent';

const CallToAction = () => {
    return (
      <section className="cta-display">
        

        

        <div className='container bg-cta'>
          
          
          <div className='row align-items-center '>
            <div className='col-lg-5  '>

                <img 
                    className=''
                    src='./assets/img/cta-starbites.png'
                    alt='...'
                    width={400}
                />

              
            </div>
            <div className='col-lg-6 align-middle'>
  
                
                  <h2 className='lead text-light font-size-30' style={{ fontWeight: 600, fontFamily: '"Unbounded", sans serif', marginTop:'0px' }}>
                    Download the <br /> Starbites App Now!
                  </h2>
                  

                

              <p className='lead text-light font-size-15'>
                Get more from your Starbites experience with the mobile app. 
                Its loaded with features and gives full access to our menu, 
                exclusive promotions and special offers at your finger tips. 
                The more you visit, the more rewards you get!
              
              </p>
              <p className='lead text-light font-size-15'>
              But that's not all for this August! App users get a 10% discount
               on all orders, along with free delivery. Earn 10 points for every 
               1 cedi spent – redeemable for delightful menu items. 
               Your Starbites journey isn't just about flavors; it's a path to savings 
               and rewards through our app.
              
              </p>

              

              <div className='row'>
              <div className='col-4  ' style={{inlineSize: "fit-content"}}>
                  <a href='https://apps.apple.com/gh/app/starbites/id1573083411'>
                            <img 
                                className=''
                                src='./assets/img/apple_logo.png'
                                alt='...'
                                width={120}
                                
                    
                            />

                        </a>

                    <a href='https://play.google.com/store/apps/details?id=com.starbiteapp'>
                        <img 
                            className=''
                            src='./assets/img/google_logo.svg'
                            alt='...'
                            width={130}
                            style={{paddingLeft: '0.5rem'}}
                            
                        
                        />

                    </a>
                    

                </div> 
                <div className='col-4  '>
                    

                </div> 
                <div className='col-4  '>

                </div>    
            
            </div>

            </div>

            

          </div>
         
          
        </div>

        <ParallaxSection bgImage="./assets/img/khebab.png" />
      </section>
    );
  };


export default CallToAction
// App.js
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import BlogPage from './pages/BlogPage';


const App = () => {
 return (
    <>
     <div  style={{ 
  backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/img/groovepaper.png'})` 
}}>
       <Routes >
        
            <Route path="/" element={<Home />} />
            <Route path="/blog" element={<BlogPage />} />
         
          
          
       </Routes>
       </div>
    </>
 );
};

export default App;
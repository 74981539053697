//Branches and Map Sction


const MapSection = () => {
    return (
      <section className="pt-80 pb-80">
        

        <>
  <main  className="">
    <section className="vc_row">

    <header className="">
              <h2 className=" mb-1 primary-text text-center header-size" style={{ fontFamily: '"Unbounded", sans serif', color:'#E20512', fontWeight:'700', fontSize: '30px' }}>Get in touch</h2>
              
            </header>
      <div className="container">
        <div className="row d-flex flex-wrap align-items-stretch">
          <div className="lqd-column col-lg-5 col-xs-12 pt-10 pb-10 ">
            
            <div className="contact-form contact-form-inputs-filled contact-form-inputs-circle contact-form-button-block contact-form-button-circle ">
              
              <div className="contact-form-result hidden" />
              
            </div>
            

            <div className='row '>
            <div className='col-lg-12  '>
          
                  <div className="row">
                    <div className="lqd-column col-md-12">

                    <header className="">
              <h2 className=" mb-1 primary-text header-size font-size-19" style={{ fontFamily: '"Unbounded", sans serif', color:'#E20512', fontWeight:'700', }}>Our Branches</h2>
              
            </header>
                      <div className="lqd-rst-menu">
                        <div className="lqd-rst-menu-inner d-flex flex-wrap">
                          <div className="w-100">
                          <p class="btn btn-naked btn-icon-left  font-size-15 font-weight-bold " style={{ color: '#000000', fontFamily: 'Inter' }}>
                            <span>
                              <span class="btn-txt">Accra</span>
                              <span class="btn-icon"><i className="fa fa-location-dot"></i></span>
                            </span>
                          </p>
                            <ul className="reset-ul inline-nav">
                              

                              <li style={{ marginBottom: '10px' }}>
                              <a href="#tab-2" class="btn btn-default text-uppercase btn-sm circle btn-bordered border-thin font-size-12 lh-15 font-weight-bold ltr-sp-05 mb-2 p-2"
                                  aria-expanded="false"
                                  aria-controls="tab-2"
                                  role="tab"
                                  data-toggle="tab"
                                  style={{ color: '#838383', borderColor: '#838383' }}
                                  >
                                  <span>
                                    <span class="btn-txt">Westlands</span>
                                  </span>
                                </a>
                              </li>

                              <li style={{ marginBottom: '10px'}}>
                                <a href="#tab-4" class="btn btn-default text-uppercase btn-sm circle btn-bordered border-thin font-size-12 lh-15 font-weight-bold ltr-sp-05 mb-2 p-2"
                                  aria-expanded="false"
                                  aria-controls="tab-4"
                                  role="tab"
                                  data-toggle="tab"
                                  style={{ color: '#838383', borderColor: '#838383' }}
                                  >
                                  <span>
                                    <span class="btn-txt">Tesano</span>
                                  </span>
                                </a>
                              </li>

                              <li style={{ marginBottom: '10px' }}>
                                <a href="#tab-5" class="btn btn-default text-uppercase btn-sm circle btn-bordered border-thin font-size-12 lh-15 font-weight-bold ltr-sp-05 mb-2 p-2"
                                  aria-expanded="false"
                                  aria-controls="tab-5"
                                  role="tab"
                                  data-toggle="tab"
                                  style={{ color: '#838383', borderColor: '#838383' }}
                                  >
                                  <span>
                                    <span class="btn-txt">Dansoman</span>
                                  </span>
                                </a>
                              </li>

                              <li style={{ marginBottom: '10px' }}>
                                <a href="#tab-6" class="btn btn-default text-uppercase btn-sm circle btn-bordered border-thin font-size-12 lh-15 font-weight-bold ltr-sp-05 mb-2 p-2"
                                  aria-expanded="false"
                                  aria-controls="tab-6"
                                  role="tab"
                                  data-toggle="tab"
                                  style={{ color: '#838383', borderColor: '#838383' }}
                                  >
                                  <span>
                                    <span class="btn-txt">Ashaley Botwe</span>
                                  </span>
                                </a>
                              </li>

                              <li style={{ marginBottom: '10px' }}>
                                <a href="#tab-6" class="btn btn-default text-uppercase btn-sm circle btn-bordered border-thin font-size-12 lh-15 font-weight-bold ltr-sp-05 mb-2 p-2"
                                  aria-expanded="false"
                                  aria-controls="tab-6"
                                  role="tab"
                                  data-toggle="tab"
                                  style={{ color: '#838383', borderColor: '#838383' }}
                                  >
                                  <span>
                                    <span class="btn-txt">East Legon</span>
                                  </span>
                                </a>
                              </li>

                            </ul>
                           
                          </div>
                          
                        </div>
                        {/* /.lqd-rst-menu-inner */}
                      </div>
                      {/* /.lqd-rst-menu */}
                      <div className="lqd-rst-menu">
                        <div className="lqd-rst-menu-inner d-flex flex-wrap">
                          <div className="w-100">
                          <p class="btn btn-naked btn-icon-left  font-size-15 font-weight-bold " style={{ color: '#000000', fontFamily: 'Inter' }}>
                            <span>
                              <span class="btn-txt">Kumasi</span>
                              <span class="btn-icon"><i className="fa fa-location-dot"></i></span>
                            </span>
                          </p>
                            <ul className="reset-ul inline-nav " role="tablist">

                              <li
                                role="presentation"
                                className="font-weight-normal font-size-13 ltr-sp-2 "
                                style={{ marginBottom: '10px' }}
                               >
                                <a href="#tab-8" class="btn btn-default text-uppercase btn-sm circle btn-bordered border-thin font-size-12 lh-15 font-weight-bold ltr-sp-05 mb-2 p-2"
                                  aria-expanded="false"
                                  aria-controls="tab-8"
                                  role="tab"
                                  data-toggle="tab"
                                  style={{ color: '#838383', borderColor: '#838383'  }}
                                  >
                                  <span>
                                    <span class="btn-txt">Asokwa</span>
                                  </span>
                                </a>
                              </li>


                              <li
                                role="presentation"
                                className="font-weight-normal font-size-13 ltr-sp-2 "
                                style={{ marginBottom: '10px' }}
                               >
                                <a href="#tab-8" class="btn btn-default text-uppercase btn-sm circle btn-bordered border-thin font-size-12 lh-15 font-weight-bold ltr-sp-05 mb-2 p-2"
                                  aria-expanded="false"
                                  aria-controls="tab-8"
                                  role="tab"
                                  data-toggle="tab"
                                  style={{ color: '#838383', borderColor: '#838383'  }}
                                  >
                                  <span>
                                    <span class="btn-txt">Adum</span>
                                  </span>
                                </a>
                              </li>

                              <li
                                role="presentation"
                                className="font-weight-normal font-size-13 ltr-sp-2 "
                                style={{ marginBottom: '10px' }}
                               >
                                <a href="#tab-8" class="btn btn-default text-uppercase btn-sm circle btn-bordered border-thin font-size-12 lh-15 font-weight-bold ltr-sp-05 mb-2 p-2"
                                  aria-expanded="false"
                                  aria-controls="tab-8"
                                  role="tab"
                                  data-toggle="tab"
                                  style={{ color: '#838383', borderColor: '#838383'  }}
                                  >
                                  <span>
                                    <span class="btn-txt">Kentinkrono</span>
                                  </span>
                                </a>
                              </li>

                              

                              
                            </ul>
                            
                          </div>
                          
                        </div>
                        {/* /.lqd-rst-menu-inner */}
                      </div>
                      {/* /.lqd-rst-menu */}
                      <div className="lqd-rst-menu">
                        <div className="lqd-rst-menu-inner d-flex flex-wrap">
                          <div className="w-100">
                          <p class="btn btn-naked btn-icon-left  font-size-15 font-weight-bold " style={{ color: '#000000', fontFamily: 'Inter' }}>
                            <span>
                              <span class="btn-txt">Tema</span>
                              <span class="btn-icon"><i className="fa fa-location-dot"></i></span>
                            </span>
                          </p>
                            <ul className="reset-ul inline-nav " role="tablist">
                            <li
                                role="presentation"
                                className="font-weight-normal font-size-13 ltr-sp-2 "
                                style={{ marginBottom: '10px' }}
                               >
                                <a href="#tab-9" class="btn btn-default text-uppercase btn-sm circle btn-bordered border-thin font-size-12 lh-15 font-weight-bold ltr-sp-05 mb-2 p-2"
                                  aria-expanded="false"
                                  aria-controls="tab-9"
                                  role="tab"
                                  data-toggle="tab"
                                  style={{ color: '#838383', borderColor: '#838383' }}
                                  >
                                  <span>
                                    <span class="btn-txt">Tema</span>
                                  </span>
                                </a>
                              </li>
                              
                            </ul>
                            
                          </div>
                          
                        </div>
                        {/* /.lqd-rst-menu-inner */}
                      </div>
                      {/* /.lqd-rst-menu */}
                      
                      {/* /.lqd-rst-menu */}
                    </div>
                    {/* /.lqd-column col-md-6 */}
                    
                    
                    {/* /.lqd-column col-md-6 */}
                  </div>
                  {/* /.row */}
                
                {/* /.tabs-pane */}


            
            </div>
           
          </div>
          </div>
          {/* /.lqd-column col-lg-4 col-md-6 */}
          <div className="lqd-column col-lg-4 col-xs-12 col-lg-offset-2 d-flex">
            <div className="ld-gmap-container stretch-to-right align-items-middle" style={{ justifyContent: 'center' }}>

<div className="tabs-content">
                <div
                  id="tab-1"
                  className="tabs-pane fade active in"
                  role="tabpanel"
                >
                  <div className="row pt-30">
                    <img 
                      className=''
                      src='/assets/img/map.png'
                      alt='...'
                      width={1000}
                    />
                    {/* /.lqd-column col-md-6 */}
                    
                    
                    {/* /.lqd-column col-md-6 */}
                  </div>
                  {/* /.row */}
                </div>
                {/* /.tabs-pane */}
                <div
                  id="tab-2"
                  className="tabs-pane fade"
                  role="tabpanel"
                >
                  <div className="row pt-30">
                  <img 
                      className=''
                      src='/assets/img/map.png'
                      alt='...'
                      width={1000}
                    />
                    {/* /.lqd-column col-md-6 */}
                   
                    {/* /.lqd-column col-md-6 */}
                  </div>
                  {/* /.row */}
                </div>
                {/* /.tabs-pane */}
                <div
                  id="tab-3"
                  className="tabs-pane fade"
                  role="tabpanel"
                >
                  <div className="row pt-30">
                    <img 
                      className=''
                      src='/assets/img/map.png'
                      alt='...'
                      width={1000}
                    />
                    {/* /.lqd-column col-md-6 */}
                    
                    
                    {/* /.lqd-column col-md-6 */}
                  </div>
                  {/* /.row */}
                </div>

                <div
                  id="tab-4"
                  className="tabs-pane fade"
                  role="tabpanel"
                >
                  <div className="row pt-30">
                    <img 
                      className=''
                      src='/assets/img/map.png'
                      alt='...'
                      width={1000}
                    />
                    {/* /.lqd-column col-md-6 */}
                   
                    {/* /.lqd-column col-md-6 */}
                  </div>
                  {/* /.row */}
                </div>

                <div
                  id="tab-5"
                  className="tabs-pane fade"
                  role="tabpanel"
                >
                  <div className="row pt-30">
                    <img 
                      className=''
                      src='/assets/img/map.png'
                      alt='...'
                      width={1000}
                    />
                    {/* /.lqd-column col-md-6 */}
                   
                    {/* /.lqd-column col-md-6 */}
                  </div>
                  {/* /.row */}
                </div>


                <div
                  id="tab-6"
                  className="tabs-pane fade"
                  role="tabpanel"
                >
                  <div className="row pt-30">
                    <img 
                      className=''
                      src='/assets/img/map.png'
                      alt='...'
                      width={1000}
                    />
                    {/* /.lqd-column col-md-6 */}
                   
                    {/* /.lqd-column col-md-6 */}
                  </div>
                  {/* /.row */}
                </div>

                <div
                  id="tab-7"
                  className="tabs-pane fade"
                  role="tabpanel"
                >
                  <div className="row pt-30">
                    <img 
                      className=''
                      src='/assets/img/map.png'
                      alt='...'
                      width={1000}
                    />
                    {/* /.lqd-column col-md-6 */}
                   
                    {/* /.lqd-column col-md-6 */}
                  </div>
                  {/* /.row */}
                </div>

                <div
                  id="tab-8"
                  className="tabs-pane fade"
                  role="tabpanel"
                >
                  <div className="row pt-30">
                    <img 
                      className=''
                      src='/assets/img/map.png'
                      alt='...'
                      width={1000}
                    />
                    {/* /.lqd-column col-md-6 */}
                   
                    {/* /.lqd-column col-md-6 */}
                  </div>
                  {/* /.row */}
                </div>

                <div
                  id="tab-9"
                  className="tabs-pane fade"
                  role="tabpanel"
                >
                  <div className="row pt-30">
                  <img 
                      className=''
                      src='/assets/img/map.png'
                      alt='...'
                      width={1000}
                    />
                    {/* /.lqd-column col-md-6 */}
                   
                    {/* /.lqd-column col-md-6 */}
                  </div>
                  {/* /.row */}
                </div>
                {/* /.tabs-pane */}
              </div>
              {/* /.ld-gmap */}
            </div>
            {/* /.ld-gmap-container */}
          </div>
          {/* /.col-md-6 */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </section>
  </main>
  {/* /#content.content */}
</>

        
        
      </section>
    );
  };


export default MapSection
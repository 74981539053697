// Watch Video Animation Component

import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';


const LottieAnimation = () => {
  const animationContainer = useRef(null);

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      path: './assets/img/animation.json', // Replace 'animation.json' with the correct file name and path in the public folder
      loop: true,
      autoplay: true,
    });

    return () => {
      anim.destroy(); // Cleanup animation when the component unmounts
    };
  }, []);

  const openFresco = () => {
    const frescoContainer = document.createElement('div');
    document.body.appendChild(frescoContainer);

    window.Fresco.show({
      url: 'https://youtu.be/NxiKFuN9bnA', // Replace 'your_youtube_video_id_here' with the actual YouTube video ID
      container: frescoContainer,
      type: 'youtube',
      options: {
        controls: true,
      },
    });
  };



  return <div ref={animationContainer} className='animation-container' onClick={openFresco}/>;
};

export default LottieAnimation;


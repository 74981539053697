import React from 'react';
import NavBar from '../helpers/NavBar';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const images = [
  '/assets/img/onion-ring.png',
  '/assets/img/fruit-salad.png',
  '/assets/img/noodles.png',
];

const Header = () => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: false,
    nextArrow: false,
  };

  return (
    <header className='hero-slides-container cta-display'>
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className='hero-slide' >
            <img src={image} alt={`Slide ${index}`} style={{height:'100%', width:'100vw'}} />
          </div>
        ))}
      </Slider>
      <NavBar />
      {/* Additional header content */}
    </header>
  );
};

export default Header;

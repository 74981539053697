import React from 'react';
import NavBar from '../helpers/NavBar';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const images = [
  '/assets/img/Starbites-sandwich.jpg',
  '/assets/img/Starbites-shawarma.jpg',
  '/assets/img/noodles.png',
];

const MobileHeader = () => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: false,
    nextArrow: false,
  };

  return (
    <header className='heroImg-slides text-white text-center '>
      <Slider {...settings} style={{height: '100%'}}>
        {images.map((image, index) => (
          <div key={index} className='hero-slide' >
            <img src={image} alt={`Slide ${index}`}  style={{objectFit:'cover', height:'80vh', width:'100vw'}}/>
          </div>
        ))}
      </Slider>
      <NavBar />
      {/* Additional header content */}
    </header>
  );
};

export default MobileHeader;

// Watch Video Section

import React from "react";
import LottieAnimation from '../helpers/LottieAnimation'; 


const VideoBanner = () => {
    return (
      


      <section className=" bannerImg ">
        
        <div className="container">
          <div className="row ">
            <div className=" col-2    "></div>

            <div className=" col-8   mt-50 mb-50 text-center">  
              <LottieAnimation />
            </div>

            <div className=" col-2"></div>
              
          </div>
            
        </div>

      </section>
    );
  };


  export default VideoBanner
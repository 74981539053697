

const OrderSection = () => {
    return (
      <section className="vc_row pt-30 pb-80 ">
        {/* Your hero section content */}

        <div className='container'>
          {/* Portfolio Section Heading*/}
          <h2 className='fancy-title page-section-heading text-center  text-primary mt-10 mb-5 hero-size' style={{ fontFamily: '"Unbounded", sans serif', fontWeight:'700' }}>
                    Come for the Food, <br />
                      Stay for the Experience
          </h2>
          
          {/* Order Button*/}
          <div className='text-center mt-4'>
          <a href="https://onelink.to/r9a37c" class="btn btn-default btn-sm circle btn-bordered border-thin btn-icon-left  font-size-19 font-weight-bold text-uppercase ltr-sp-05 mb-2">
								<span>
									<span class="btn-txt">Download App</span>
									<span class="btn-icon"><i class="fa fa-ticket"></i></span>
								</span>
							</a>
          </div>
          
         
        </div>
      </section>
    );
  };


  export default OrderSection
//Footer and Contact Information

import ParallaxSection from '../helpers/ParallaxComponent';

const Footer = () => {
    return (


    <>
    <footer className="main-footer  pb-80 bg-fixed" data-sticky-footer="true">

    <ParallaxSection bgImage="./assets/img/drinks.png" />
      
      <section className="vc_row pt-80">
        <div className="container" id="contact">
          <div className="row d-flex flex-wrap align-items-start">

          <div className="lqd-column col-md-6 col-sm-6 col-xs-12 footer-center ">
              <h3 className="widget_title footer-center font-size-25">About</h3>
              <p className="font-size-16 lh-175 footer-center">
                Starbites is an award winning 
                restaurant, and food service with branches across 
                Accra, Tema and Kumasi.
              </p>
              <div className=' mt-4'>
                <a href="#" class="btn btn-default circle btn-bordered border-thin btn-icon-left  font-size-12 font-weight-bold text-uppercase ltr-sp-05 mb-2">
                  <span>
                    <span class="btn-txt">Lets Talk</span>
                    <span class="btn-icon"><i class="fa fa-star"></i></span>
                  </span>
							  </a>
              </div>
            </div>
            {/* /.lqd-column col-md-3 */}
            
            <div className="lqd-column col-md-3 col-sm-6 col-xs-12 footer-center">
              <h3 className="widget_title font-size-25">Contact us</h3>
              <p className="font-size-16 lh-175">
                
                
                Call our Call Center on, <br />
                024 243 6868/ 020 226 6200 <br />
                send us an email <br />

                <br />

                info@starbitesgh.com <br />

              </p>
            </div>
            {/* /.lqd-column col-md-3 */}

            <div className="lqd-column col-md-3 col-xs-12 footer-center">
              <h3 className="widget_title font-size-25">Follow Us</h3>
              <ul className="social-icon circle social-icon-md">
                <li>
                  <a href="https://www.instagram.com/starbitesgh/" target="_blank">
                  <span class="btn-icon"><i class="fa-brands fa-instagram"></i></span>

                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/StarbitesGH_" target="_blank">
                  <span class="btn-icon"><i class="fa-brands fa-twitter"></i></span>
                  </a>
                </li>
                <li>
                  <a href="https://www.facebook.com/starbitesgh" target="_blank">
                  <span class="btn-icon"><i class="fa-brands fa-facebook"></i></span>

                  </a>
                </li>
              </ul>
            </div>
            
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>

      <section className="vc_row mb-50">
                <div className="container">
                  <div className="row d-flex flex-wrap align-items-center">
                    
                   
                    <div className="lqd-column col-md-12 footer-center">
                      <hr />
                      <p className="font-size-16 lh-175 text-center ">
                        Powered by <a href="https://business.digistoreafrica.com/" target="_blank">Digistore</a>
                    </p>
                    </div>

                    
                    

                   
                  </div>
                  {/* /.row */}
                </div>
                {/* /.container */}
              </section>
    </footer>
    {/* /.main-footer */}
  </>


    );
  };


export default Footer
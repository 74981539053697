// components/BlogPage.js
import React from 'react';
import { Outlet } from 'react-router-dom'; // Use Outlet for nested routes

const BlogPage = () => {
  return (
    <div style={{height:'100vh'}}>
      {/* Your blog page content */}
      <h2 className='text-center text-primary pt-80' style={{margin:0}}>Blog Page</h2>
      <p className='text-center'>Blogs are currently Unavailable</p>
      <a className='nav-link text-center' href='/'>
                  Go back Home
                </a>
      {/* Use Outlet to render nested routes */}
      <Outlet />
    </div>
  );
};

export default BlogPage;
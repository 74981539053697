
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const NavBar = () => {

  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

return (
    

    <>
    {/* Navigation*/}
    <nav
        className={`navbar navbar-expand-lg text-uppercase fixed-top ${
          scrolling ? 'navbar-scrolled' : ''
        }`}
        id='mainNav'
        style={{
          position: 'fixed',
          zIndex: 1000,
          background: scrolling ? 'white' : 'transparent',
          color: scrolling ? 'black !important' : 'white !important',
          minHeight: 50,
          marginBottom: 20,
          border: scrolling ? '1px solid #ddd' : '1px solid transparent',
          transition: 'background-color 0.3s, color 0.3s, border 0.3s',
        }}
      >

      <div className='container d-flex'>
        <a className='' href='#page-top'>
        <img
          className=' '
          src='/assets/img/logo.png'
          alt='...'
         
        />
        </a>
        <button
          className='navbar-toggler text-uppercase font-weight-bold bg-primary text-white rounded'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#navbarResponsive'
          aria-controls='navbarResponsive'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          Menu
          <i className='fas fa-bars' />
        </button>
        <div className='collapse navbar-collapse ' id='navbarResponsive' style={{ width: "100%" }}>
          <ul className='navbar-nav ms-auto' >
            <li className='nav-item mx-0 mx-lg-1'>
              
              <Link to="/" ><a
                className={`nav-link py-3 px-0 px-lg-3 rounded ${
                  scrolling ? 'nav-link-scrolled' : ''
                }`}
                href='#'
              >
                Home
              </a></Link>
            </li>
            <li className='nav-item mx-0 mx-lg-1'>
              <a
                className={`nav-link py-3 px-0 px-lg-3 rounded ${
                  scrolling ? 'nav-link-scrolled' : ''
                }`}
                href='#about'
              >
                About
              </a>
            </li>
            <li className='nav-item mx-0 mx-lg-1'>
              <a className={`nav-link py-3 px-0 px-lg-3 rounded ${
                    scrolling ? 'nav-link-scrolled' : ''
                  }`} href='#menu'>
                Our Menu
              </a>
            </li>
            <li className='nav-item mx-0 mx-lg-1'>
              
              <Link to="/blog" >
              <a className={`nav-link py-3 px-0 px-lg-3 rounded ${
                    scrolling ? 'nav-link-scrolled' : ''
                  }`}>
                Blog
              </a>

              </Link>
            </li>
            <li className='nav-item mx-0 mx-lg-1'>
              <a
                className={`nav-link py-3 px-0 px-lg-3 rounded ${
                  scrolling ? 'nav-link-scrolled' : ''
                }`}
                href='#contact'
              >
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    {/* Masthead*/}
    
    </>
    


    );
  };


  export default NavBar
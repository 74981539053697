// About Section

const About = () => {
    return (
      <section className=" ptr-80 pb-80 ">
        

        
        <div className='container' id="about">
          
          {/* About Section Content*/}
          <div className='row align-items-center'>
            <div className='col-lg-5 ms-auto '>

            <h2 className='page-section-heading font-size-23 text-primary mb-3 res-text-center res-text-left' style={{ fontFamily: '"Unbounded", sans serif', fontWeight:'700'}}>
            The Starbites Experience
              </h2>

              

              <p className='lead text-dark res-text-center'>
              Starbites®, a truly Ghanaian brand opened the doors of its very first restaurant in East Legon, Accra in 2011,
               and created a variety of foods for the growing multicultural society of Ghana, catering to a diverse range of palates and tastes.
              
              </p>

              <p className='lead text-dark res-text-center'>
              Starbites® has grown since then, creating for itself a unique Ghanaian brand and opening branches within the 
              capital and across the country.
              </p>
            </div>

            {/* About Section Image*/}
            <div className='col-lg-6 align-middle'>
              <img 
              className=''
              src='/assets/img/starbite-experience.png'
              alt='...'
              width={600}
              />
            </div>
          </div>
         
          
        </div>
        
      </section>
    );
  };

export default About 
// Opening Times Sections along with the 2nd Parallax Image

import React from "react";




const OpeningTimes = () => {
    return (
      <section className=" ">
        

        
        <div className='container' >
        
          
          {/* Opening Times Content*/}
          <div className='row '>
            <div className='col-lg-7  '>
          
                  <div className="row">
                    <div className="lqd-column col-md-10">
                      <div className="lqd-rst-menu" style={{ paddingBottom: '2px' }}>
                        <div className="lqd-rst-menu-inner d-flex flex-wrap">
                          <div className="w-60">
                            <h2 className="font-size-16 font-weight-normal mt-0 mb-1" style={{ fontFamily: '"Inter", sans serif' }}>
                              Main Branches
                            </h2>
                           
                          </div>
                          <div className="w-40 text-right">
                            <div className="lqd-rst-menu pricing font-size-16 font-weight-normal my-0 lh-15" style={{ paddingBottom: '2px' }}>
                              <ul className="price">Weekdays   7am - 11pm</ul>
                            </div>
                          </div>
                        </div>
                        {/* /.lqd-rst-menu-inner */}
                      </div>
                      {/* /.lqd-rst-menu */}
                      <div className="lqd-rst-menu" style={{ paddingBottom: '2px' }}>
                        <div className="lqd-rst-menu-inner d-flex flex-wrap" style={{ paddingBottom: '2px' }}>
                          <div className="w-60">
                            <h2 className="font-size-16 font-weight-normal mt-0 mb-1" style={{ fontFamily: '"Inter", sans serif' }}>
                              Food to Go Branches
                            </h2>
                            
                          </div>
                          <div className="w-40 text-right">
                            <div className="lqd-rst-menu pricing font-size-16 font-weight-normal my-0 lh-15" style={{ paddingBottom: '2px' }}>
                              <ul className="price">Weekdays   6am - 10pm</ul>
                            </div>
                          </div>
                        </div>
                        {/* /.lqd-rst-menu-inner */}
                      </div>
                      {/* /.lqd-rst-menu */}
                      <div className="lqd-rst-menu" style={{ paddingBottom: '2px' }}>
                        <div className="lqd-rst-menu-inner d-flex flex-wrap" style={{ paddingBottom: '2px' }}>
                          <div className="w-60">
                            <h2 className="font-size-16 font-weight-normal mt-0 mb-1" style={{ fontFamily: '"Inter", sans serif' }}>
                              Express (Kumasi)
                            </h2>
                            
                            
                          </div>
                          <div className="w-40 text-right">
                            <div className="lqd-rst-menu pricing font-size-16 font-weight-normal my-0 lh-15" style={{ paddingBottom: '2px' }}>
                              <ul className="price">Weekdays   6am - 10pm</ul>
                            </div>
                          </div>
                        </div>
                        {/* /.lqd-rst-menu-inner */}
                      </div>
                      {/* /.lqd-rst-menu */}
                      <div className="lqd-rst-menu" style={{ paddingBottom: '2px' }}>
                        <div className="lqd-rst-menu-inner d-flex flex-wrap">
                          <div className="w-60">
                            <h2 className="font-size-16 font-weight-normal mt-0 mb-1" style={{ fontFamily: '"Inter", sans serif' }}>
                              Express (Accra)
                            </h2>
                            
                          </div>
                          <div className="w-40 text-right">
                            <div className="lqd-rst-menu pricing font-size-16 font-weight-normal my-0 lh-15" style={{ paddingBottom: '2px' }}>
                              <ul className="price">Weekdays   6am - 8pm</ul>
                            </div>
                          </div>

                         
                        </div>
                        {/* /.lqd-rst-menu-inner */}
                      </div>
                      {/* /.lqd-rst-menu */}
                    </div>
                    {/* /.lqd-column col-md-6 */}
                    
                    
                    {/* /.lqd-column col-md-6 */}
                  </div>
                  {/* /.row */}
                
                {/* /.tabs-pane */}


            
            </div>
            {/* Get in Touch content */}
            <div className='col-lg-5 align-items-start pb-10'>
              <img className=" get align-items-start header-size"
                src='/assets/img/get-in-touch.png'
                alt='...'
                width={600}
              
              />
              
            </div>
          </div>
         
          
        </div>

        {/*Parallax Section */}

        
        
      </section>


      
    );
  };


export default OpeningTimes
// App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Header from '../components/sections/Header'
import OrderSection from '../components/sections/OrderSection';
import About from '../components/sections/About';
import VideoBanner from '../components/sections/VideoBanner';
import CallToAction from '../components/sections/CallToAction';
import TweetsSection from '../components/sections/TweetsSection';
import MapSection from '../components/sections/MapSection';
import OpeningTimes from '../components/sections/OpeningTimes';
import Footer from '../components/sections/Footer';
import Menu from '../components/sections/Menu';
import CallToActionMobile from '../components/sections/CallToActionMobile';
import Popup from '../components/helpers/Popup';
import MobileHeader from '../components/sections/MobileHeader';


const Home = () => {



  return (
    
      <div>
        <Popup />
        <Header />
        <div className='cta-display-mobile'>
          <MobileHeader />
        </div>
        
        <OrderSection />
        <About />
        <VideoBanner />
        <Menu />
        <CallToAction />
        <div className='cta-display-mobile'>
          <CallToActionMobile />
        </div>
        <TweetsSection />
        <MapSection />
        <OpeningTimes />
        <Footer />
      </div>
  
  );
};

export default Home;

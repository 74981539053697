//This is the Component that causes the parallax effect

import React from 'react';
import { Parallax } from 'react-parallax';

const ParallaxSection = ({ bgImage }) => {

    const handleScroll = () => {
        const scrollValue = window.scrollY;
        const parallaxElement = document.getElementById('parallax-section');
        if (parallaxElement) {
          // Adjust the background position based on the scroll position
          parallaxElement.style.backgroundPositionY = `${scrollValue * 0.5}px`;
        }
      };
    
      React.useEffect(() => {
        // Attach the scroll event listener
        window.addEventListener('scroll', handleScroll);

        return () => {
            // Remove the scroll event listener when the component unmounts
            window.removeEventListener('scroll', handleScroll);
          };
        }, []);



    return (
      <div>
        {/* Other content before the parallax section */}
        <Parallax
          bgImage={bgImage} 
          bgImageAlt="Parallax Background"
          strength={300}
          
          
        >
          {/* Parallax content */}
          <div
            style={{
              height: "25vh",
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              color: '#fff',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
              backgroundSize: 'contain', // Set the background property of the image to cover
            backgroundPosition: 'center',
            backgroundAttachment: 'fixed',
            
            }}
          >
            
          </div>
        </Parallax>
        {/* Other content after the parallax section */}
      </div>
    );
  };
  
  export default ParallaxSection;
  
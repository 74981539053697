import React from 'react';
// You can create a CSS file for styling

const TwitterEmbedCard = ({ avatarUrl, tweetLink, username, handle, content, likes, retweets, timestamp }) => {
  return (
    <div className="twitter-card">
      <div className="twitter-header">
      <div className="twitter-logo"></div> {/* Twitter logo */}
      <div className="avatar" style={{ backgroundImage: `url(${avatarUrl})`, backgroundSize:'contain' }}></div>
        <div className="user-info">
          <div className="username">{username}</div>
          <div className="handle">{handle}</div>
        </div>
      </div>
      <div className="tweet-content">
        {/* Embed the actual tweet here */}
        <a href={tweetLink} target="_blank" rel="noopener noreferrer" className="tweet-content">
            {content}
        </a>
      </div>
      <div className="twitter-footer">
        <div className="footer-icon"></div>
        <div className="footer-count">{likes}</div>
        
        <div className="tweet-timestamp footer-count">
        {timestamp}
      </div>
      </div>
    </div>
  );
};

export default TwitterEmbedCard;

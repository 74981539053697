import React, { useState } from "react";

const Menu = () => {
    // Dummy menu items for each subcategory
    const starbitesMenu = {
        breakfast: [
          { name: "Texas BBQ Chicken", price: 13, description: "Grilled chicken with Texas BBQ sauce" },
          { name: "Fully Loaded Cajun Chicken Burgers", price: 9, description: "Spicy Cajun chicken burgers" },
          { name: "American Burnt Onion Dip", price: 11, description: "Creamy burnt onion dip" },
          { name: "American Pancakes", price: 24, description: "Fluffy pancakes with syrup" },
          { name: "American Burnt Onion Dip", price: 11, description: "Creamy burnt onion dip" },
          { name: "American Pancakes", price: 24, description: "Fluffy pancakes with syrup" },
        ],
        lunch: [
          { name: "American Burnt Onion Dip", price: 11, description: "Creamy burnt onion dip" },
          { name: "American Pancakes", price: 24, description: "Fluffy pancakes with syrup" },
          { name: "American Burnt Onion Dip", price: 11, description: "Creamy burnt onion dip" },
          { name: "American Pancakes", price: 24, description: "Fluffy pancakes with syrup" },
          { name: "American Burnt Onion Dip", price: 11, description: "Creamy burnt onion dip" },
          { name: "American Pancakes", price: 24, description: "Fluffy pancakes with syrup" },
        ],
        brunch: [
          { name: "American Burnt Onion Dip", price: 11, description: "Creamy burnt onion dip" },
          { name: "American Pancakes", price: 24, description: "Fluffy pancakes with syrup" },
          { name: "American Burnt Onion Dip", price: 11, description: "Creamy burnt onion dip" },
          { name: "American Pancakes", price: 24, description: "Fluffy pancakes with syrup" },
          { name: "American Burnt Onion Dip", price: 11, description: "Creamy burnt onion dip" },
          { name: "American Pancakes", price: 24, description: "Fluffy pancakes with syrup" },
        ], // Add brunch menu items here
        dinner: [
          { name: "American Burnt Onion Dip", price: 11, description: "Creamy burnt onion dip" },
          { name: "American Pancakes", price: 24, description: "Fluffy pancakes with syrup" },
          { name: "American Burnt Onion Dip", price: 11, description: "Creamy burnt onion dip" },
          { name: "American Pancakes", price: 24, description: "Fluffy pancakes with syrup" },
          { name: "American Burnt Onion Dip", price: 11, description: "Creamy burnt onion dip" },
          { name: "American Pancakes", price: 24, description: "Fluffy pancakes with syrup" },
        ], // Add dinner menu items here
        desserts: [
          { name: "American Burnt Onion Dip", price: 11, description: "Creamy burnt onion dip" },
          { name: "American Pancakes", price: 24, description: "Fluffy pancakes with syrup" },
          { name: "American Burnt Onion Dip", price: 11, description: "Creamy burnt onion dip" },
          { name: "American Pancakes", price: 24, description: "Fluffy pancakes with syrup" },
          { name: "American Burnt Onion Dip", price: 11, description: "Creamy burnt onion dip" },
          { name: "American Pancakes", price: 24, description: "Fluffy pancakes with syrup" },
        ], // Add dessert menu items here
      };
    
      const starbitesFood2GoMenu = {
        breakfast: [
          { name: "Pasta Carbonara", price: 15, description: "Classic Italian pasta with creamy sauce" },
          { name: "Texas BBQ Chicken", price: 13, description: "Grilled chicken with Texas BBQ sauce" },
          { name: "Fully Loaded Cajun Chicken Burgers", price: 9, description: "Spicy Cajun chicken burgers" },
          { name: "Texas BBQ Chicken", price: 13, description: "Grilled chicken with Texas BBQ sauce" },
          { name: "Fully Loaded Cajun Chicken Burgers", price: 9, description: "Spicy Cajun chicken burgers" },
        ],
        lunch: [
          { name: "Margherita Pizza", price: 12, description: "Tomato, mozzarella, and basil pizza" },
          { name: "Chicken Alfredo", price: 17, description: "Creamy chicken alfredo pasta" },
          { name: "Texas BBQ Chicken", price: 13, description: "Grilled chicken with Texas BBQ sauce" },
          { name: "Fully Loaded Cajun Chicken Burgers", price: 9, description: "Spicy Cajun chicken burgers" },
          { name: "Texas BBQ Chicken", price: 13, description: "Grilled chicken with Texas BBQ sauce" },
          { name: "Fully Loaded Cajun Chicken Burgers", price: 9, description: "Spicy Cajun chicken burgers" },
        ],
        brunch: [
          { name: "Texas BBQ Chicken", price: 13, description: "Grilled chicken with Texas BBQ sauce" },
          { name: "Fully Loaded Cajun Chicken Burgers", price: 9, description: "Spicy Cajun chicken burgers" },
          { name: "Texas BBQ Chicken", price: 13, description: "Grilled chicken with Texas BBQ sauce" },
          { name: "Fully Loaded Cajun Chicken Burgers", price: 9, description: "Spicy Cajun chicken burgers" },
        ], // Add brunch menu items here
        dinner: [
          { name: "Texas BBQ Chicken", price: 13, description: "Grilled chicken with Texas BBQ sauce" },
          { name: "Fully Loaded Cajun Chicken Burgers", price: 9, description: "Spicy Cajun chicken burgers" },
          { name: "Texas BBQ Chicken", price: 13, description: "Grilled chicken with Texas BBQ sauce" },
          { name: "Fully Loaded Cajun Chicken Burgers", price: 9, description: "Spicy Cajun chicken burgers" },
          { name: "Texas BBQ Chicken", price: 13, description: "Grilled chicken with Texas BBQ sauce" },
          { name: "Fully Loaded Cajun Chicken Burgers", price: 9, description: "Spicy Cajun chicken burgers" },
        ], // Add dinner menu items here
        desserts: [
          { name: "Texas BBQ Chicken", price: 13, description: "Grilled chicken with Texas BBQ sauce" },
          { name: "Fully Loaded Cajun Chicken Burgers", price: 9, description: "Spicy Cajun chicken burgers" },
          { name: "Texas BBQ Chicken", price: 13, description: "Grilled chicken with Texas BBQ sauce" },
          { name: "Fully Loaded Cajun Chicken Burgers", price: 9, description: "Spicy Cajun chicken burgers" },
        ], // Add dessert menu items here
      };
    
      const starbitesExpressMenu = {
        breakfast: [
          { name: "Fully Loaded Cajun Chicken Burgers", price: 9, description: "Spicy Cajun chicken burgers" },
          { name: "Texas BBQ Chicken", price: 13, description: "Grilled chicken with Texas BBQ sauce" },
          { name: "Fully Loaded Cajun Chicken Burgers", price: 9, description: "Spicy Cajun chicken burgers" },
        ],
        lunch: [
          { name: "Fully Loaded Cajun Chicken Burgers", price: 9, description: "Spicy Cajun chicken burgers" },
          { name: "Texas BBQ Chicken", price: 13, description: "Grilled chicken with Texas BBQ sauce" },
          { name: "Fully Loaded Cajun Chicken Burgers", price: 9, description: "Spicy Cajun chicken burgers" },
        ],
        brunch: [
          { name: "Green Thai Curry", price: 14, description: "Traditional Thai curry with vegetables" },
          { name: "Fully Loaded Cajun Chicken Burgers", price: 9, description: "Spicy Cajun chicken burgers" },
          { name: "Texas BBQ Chicken", price: 13, description: "Grilled chicken with Texas BBQ sauce" },
          { name: "Fully Loaded Cajun Chicken Burgers", price: 9, description: "Spicy Cajun chicken burgers" },
        ],
        dinner: [
          { name: "Fully Loaded Cajun Chicken Burgers", price: 9, description: "Spicy Cajun chicken burgers" },
          { name: "Texas BBQ Chicken", price: 13, description: "Grilled chicken with Texas BBQ sauce" },
          { name: "Fully Loaded Cajun Chicken Burgers", price: 9, description: "Spicy Cajun chicken burgers" },
          { name: "Fully Loaded Cajun Chicken Burgers", price: 9, description: "Spicy Cajun chicken burgers" },
          { name: "Texas BBQ Chicken", price: 13, description: "Grilled chicken with Texas BBQ sauce" },
          { name: "Fully Loaded Cajun Chicken Burgers", price: 9, description: "Spicy Cajun chicken burgers" },
        ], // Add dinner menu items here
        desserts: [
          { name: "Tiramisu", price: 8, description: "Classic Italian dessert" },
          { name: "Caesar Salad", price: 9, description: "Romaine lettuce with Caesar dressing" },
          { name: "Fully Loaded Cajun Chicken Burgers", price: 9, description: "Spicy Cajun chicken burgers" },
          { name: "Texas BBQ Chicken", price: 13, description: "Grilled chicken with Texas BBQ sauce" },
          { name: "Fully Loaded Cajun Chicken Burgers", price: 9, description: "Spicy Cajun chicken burgers" },
        ],
      };

  // State to manage visibility of menu items
  const [showAdditionalMenu, setShowAdditionalMenu] = useState(false);

  // Function to toggle visibility of additional menu items
  const toggleAdditionalMenu = () => {
    setShowAdditionalMenu(!showAdditionalMenu);
  };

  // Function to get visible menu items based on state
  const getVisibleMenu = (menuItems) => {
    if (showAdditionalMenu) {
      return menuItems;
    } else {
      return menuItems.slice(0, 3); // Show only the first 3 items
    }
  };

  return (
    <section className="vc_row bg-cover bg-fixed pt-100 pt-80 pb-80" >
      <div className="container" id="menu">
        <div className="row">
        <div className="lqd-column col-md-8 col-md-offset-2">
            <header className="fancy-title mb-45 text-center ">
              <h5 className="my-0 pb-3 header-size" style={{ fontFamily: '"Unbounded", sans serif', fontWeight:'700', fontSize: '30px' }}>Our Menu</h5>
              <p className="my-0  lead" style={{ fontFamily: '"Inter", sans serif' }}>Our strategically positioned restaurants are designed to provide casual dine-in or quick service with our extensive menu</p>
            </header>
          </div>
          <div className="lqd-column col-md-12">
            <div className="tabs tabs-nav-right tabs-nav-underlined tabs-nav-active-underlined tabs-nav-lg">
              <ul className="nav tabs-nav" role="tablist">
                <li className="active">
                  <a href="#starbites" data-toggle="tab" style={{padding:'10px'}}>
                    <img 
                      className=''
                      src='/assets/img/starbites-logo-plain.svg'
                      alt='...'
                      width={90}
                    />
                  </a>
                </li>
                <li>
                  <a href="#starbites-food2go" data-toggle="tab" style={{padding:'10px'}}>
                    <img 
                      className=''
                      src='/assets/img/starbite-menu-catg.svg'
                      alt='...'
                      width={90}
                    />
                  </a>
                </li>
                {/* <li>
                  <a href="#starbites-express" data-toggle="tab" style={{padding:'10px'}}>
                  <img 
                      className=''
                      src='/assets/img/starbite-menu-express.svg'
                      alt='...'
                      width={90}
                    />
                  </a>
                </li> */}
              </ul>
              <div className="tabs-content starbitess">
                <div id="starbites" className="tabs-pane fade in active" role="tabpanel">
                  {/* Starbites Tab Content */}
                  <div className="row pt-10 starbitess" style={{ maxHeight: "50vh", overflowY: "scroll" }}>
                    <div className="col-md-12">
                      <h3 className="font-size-25">Breakfast</h3>
                      <div className="row">
                        {getVisibleMenu(starbitesMenu.breakfast).map((menuItem, index) => (
                          <div key={index} className="lqd-rst-menu col-md-4">
                            <div className="lqd-rst-menu-inner d-flex flex-wrap">
                              <div className="w-80">
                                <h2 className="font-size-15 font-weight-normal mt-0 mb-1">{menuItem.name}</h2>
                                <p className="reset-ul inline-nav">{menuItem.description}</p>
                              </div>
                              <div className="w-20 text-right">
                                <div className="lqd-rst-menu-price pricing font-size-15 font-weight-bold my-0 lh-15">
                                  <span className="price text-primary ">GHS {menuItem.price}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    {showAdditionalMenu && (
                      <>
                        {/* Additional Subcategories and Menu Items */}
                        <div className="col-md-12">
                          <h3 className="font-size-25">Lunch</h3>
                          <div className="row">
                            {starbitesMenu.lunch.map((menuItem, index) => (
                              <div key={index} className="lqd-rst-menu col-md-4">
                                <div className="lqd-rst-menu-inner d-flex flex-wrap">
                                  <div className="w-80">
                                    <h2 className="font-size-15 font-weight-normal mt-0 mb-1">{menuItem.name}</h2>
                                    <p className="reset-ul inline-nav">{menuItem.description}</p>
                                  </div>
                                  <div className="w-20 text-right">
                                    <div className="lqd-rst-menu-price pricing font-size-15 font-weight-bold my-0 lh-15">
                                      <span className="price text-primary">GHS {menuItem.price}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <h3 className="font-size-25">Brunch</h3>
                          <div className="row">
                            {starbitesMenu.brunch.map((menuItem, index) => (
                              <div key={index} className="lqd-rst-menu col-md-4">
                                <div className="lqd-rst-menu-inner d-flex flex-wrap">
                                  <div className="w-80">
                                    <h2 className="font-size-15 font-weight-normal mt-0 mb-1">{menuItem.name}</h2>
                                    <p className="reset-ul inline-nav">{menuItem.description}</p>
                                  </div>
                                  <div className="w-20 text-right">
                                    <div className="lqd-rst-menu-price pricing font-size-15 font-weight-bold my-0 lh-15">
                                      <span className="price text-primary">GHS {menuItem.price}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <h3 className="font-size-25">Dinner</h3>
                          <div className="row">
                            {starbitesMenu.dinner.map((menuItem, index) => (
                              <div key={index} className="lqd-rst-menu col-md-4">
                                <div className="lqd-rst-menu-inner d-flex flex-wrap">
                                  <div className="w-80">
                                    <h2 className="font-size-15 font-weight-normal mt-0 mb-1">{menuItem.name}</h2>
                                    <p className="reset-ul inline-nav">{menuItem.description}</p>
                                  </div>
                                  <div className="w-20 text-right">
                                    <div className="lqd-rst-menu-price pricing font-size-15 font-weight-bold my-0 lh-15">
                                      <span className="price text-primary">GHS {menuItem.price}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <h3 className="font-size-25">Desserts</h3>
                          <div className="row">
                            {starbitesMenu.desserts.map((menuItem, index) => (
                              <div key={index} className="lqd-rst-menu col-md-4">
                                <div className="lqd-rst-menu-inner d-flex flex-wrap">
                                  <div className="w-80">
                                    <h2 className="font-size-15 font-weight-normal mt-0 mb-1">{menuItem.name}</h2>
                                    <p className="reset-ul inline-nav">{menuItem.description}</p>
                                  </div>
                                  <div className="w-20 text-right">
                                    <div className="lqd-rst-menu-price pricing font-size-15 font-weight-bold my-0 lh-15">
                                      <span className="price text-primary">GHS {menuItem.price}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div id="starbites-food2go" className="tabs-pane fade" role="tabpanel">
                  {/* Starbites Food 2 Go Tab Content */}
                  <div className="row pt-10" style={{ maxHeight: "50vh", overflowY: "scroll" }}>
                    <div className="col-md-12">
                      <h3 className="font-size-25">Breakfast</h3>
                      <div className="row">
                        {getVisibleMenu(starbitesFood2GoMenu.breakfast).map((menuItem, index) => (
                          <div key={index} className="lqd-rst-menu col-md-4">
                            <div className="lqd-rst-menu-inner d-flex flex-wrap">
                              <div className="w-80">
                                <h2 className="font-size-15 font-weight-normal mt-0 mb-1">{menuItem.name}</h2>
                                <p className="reset-ul inline-nav">{menuItem.description}</p>
                              </div>
                              <div className="w-20 text-right">
                                <div className="lqd-rst-menu-price pricing font-size-15 font-weight-bold my-0 lh-15">
                                  <span className="price text-primary">GHS {menuItem.price}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    {showAdditionalMenu && (
                      <>
                        {/* Additional Subcategories and Menu Items */}
                        <div className="col-md-12">
                          <h3 className="font-size-25">Lunch</h3>
                          <div className="row">
                            {starbitesFood2GoMenu.lunch.map((menuItem, index) => (
                              <div key={index} className="lqd-rst-menu col-md-4">
                                <div className="lqd-rst-menu-inner d-flex flex-wrap">
                                  <div className="w-80">
                                    <h2 className="font-size-15 font-weight-normal mt-0 mb-1">{menuItem.name}</h2>
                                    <p className="reset-ul inline-nav">{menuItem.description}</p>
                                  </div>
                                  <div className="w-20 text-right">
                                    <div className="lqd-rst-menu-price pricing font-size-15 font-weight-bold my-0 lh-15">
                                      <span className="price text-primary">GHS {menuItem.price}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <h3 className="font-size-25">Brunch</h3>
                          <div className="row">
                            {starbitesFood2GoMenu.brunch.map((menuItem, index) => (
                              <div key={index} className="lqd-rst-menu col-md-4">
                                <div className="lqd-rst-menu-inner d-flex flex-wrap">
                                  <div className="w-80">
                                    <h2 className="font-size-15 font-weight-normal mt-0 mb-1">{menuItem.name}</h2>
                                    <p className="reset-ul inline-nav">{menuItem.description}</p>
                                  </div>
                                  <div className="w-20 text-right">
                                    <div className="lqd-rst-menu-price pricing font-size-15 font-weight-bold my-0 lh-15">
                                      <span className="price text-primary">GHS {menuItem.price}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <h3 className="font-size-25">Dinner</h3>
                          <div className="row">
                            {starbitesFood2GoMenu.dinner.map((menuItem, index) => (
                              <div key={index} className="lqd-rst-menu col-md-4">
                                <div className="lqd-rst-menu-inner d-flex flex-wrap">
                                  <div className="w-80">
                                    <h2 className="font-size-15 font-weight-normal mt-0 mb-1">{menuItem.name}</h2>
                                    <p className="reset-ul inline-nav">{menuItem.description}</p>
                                  </div>
                                  <div className="w-20 text-right">
                                    <div className="lqd-rst-menu-price pricing font-size-15 font-weight-bold my-0 lh-15">
                                      <span className="price text-primary">GHS {menuItem.price}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <h3 className="font-size-25">Desserts</h3>
                          <div className="row">
                            {starbitesFood2GoMenu.desserts.map((menuItem, index) => (
                              <div key={index} className="lqd-rst-menu col-md-4">
                                <div className="lqd-rst-menu-inner d-flex flex-wrap">
                                  <div className="w-80">
                                    <h2 className="font-size-15 font-weight-normal mt-0 mb-1">{menuItem.name}</h2>
                                    <p className="reset-ul inline-nav">{menuItem.description}</p>
                                  </div>
                                  <div className="w-20 text-right">
                                    <div className="lqd-rst-menu-price pricing font-size-15 font-weight-bold my-0 lh-15">
                                      <span className="price text-primary">GHS {menuItem.price}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  {/* Add similar structure as above */}
                </div>
                <div id="starbites-express" className="tabs-pane fade" role="tabpanel">
                  {/* Starbites Express Tab Content */}
                  <div className="row pt-30" style={{ maxHeight: "50vh", overflowY: "scroll" }}>
                    <div className="col-md-12">
                      <h3>Breakfast</h3>
                      <div className="row">
                        {getVisibleMenu(starbitesExpressMenu.breakfast).map((menuItem, index) => (
                          <div key={index} className="lqd-rst-menu col-md-4">
                            <div className="lqd-rst-menu-inner d-flex flex-wrap">
                              <div className="w-80">
                                <h2 className="font-size-15 font-weight-normal mt-0 mb-1">{menuItem.name}</h2>
                                <p className="reset-ul inline-nav">{menuItem.description}</p>
                              </div>
                              <div className="w-20 text-right">
                                <div className="lqd-rst-menu-price pricing font-size-16 font-weight-bold my-0 lh-15">
                                  <span className="price text-primary">GHS {menuItem.price}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    {showAdditionalMenu && (
                      <>
                        {/* Additional Subcategories and Menu Items */}
                        <div className="col-md-12">
                          <h3>Lunch</h3>
                          <div className="row">
                            {starbitesExpressMenu.lunch.map((menuItem, index) => (
                              <div key={index} className="lqd-rst-menu col-md-4">
                                <div className="lqd-rst-menu-inner d-flex flex-wrap">
                                  <div className="w-80">
                                    <h2 className="font-size-16 font-weight-normal mt-0 mb-1">{menuItem.name}</h2>
                                    <p className="reset-ul inline-nav">{menuItem.description}</p>
                                  </div>
                                  <div className="w-20 text-right">
                                    <div className="lqd-rst-menu-price pricing font-size-20 font-weight-normal my-0 lh-15">
                                      <span className="price">${menuItem.price}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <h3>Brunch</h3>
                          <div className="row">
                            {starbitesExpressMenu.brunch.map((menuItem, index) => (
                              <div key={index} className="lqd-rst-menu col-md-4">
                                <div className="lqd-rst-menu-inner d-flex flex-wrap">
                                  <div className="w-80">
                                    <h2 className="font-size-16 font-weight-normal mt-0 mb-1">{menuItem.name}</h2>
                                    <p className="reset-ul inline-nav">{menuItem.description}</p>
                                  </div>
                                  <div className="w-20 text-right">
                                    <div className="lqd-rst-menu-price pricing font-size-20 font-weight-normal my-0 lh-15">
                                      <span className="price">${menuItem.price}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <h3>Dinner</h3>
                          <div className="row">
                            {starbitesExpressMenu.dinner.map((menuItem, index) => (
                              <div key={index} className="lqd-rst-menu col-md-4">
                                <div className="lqd-rst-menu-inner d-flex flex-wrap">
                                  <div className="w-80">
                                    <h2 className="font-size-16 font-weight-normal mt-0 mb-1">{menuItem.name}</h2>
                                    <p className="reset-ul inline-nav">{menuItem.description}</p>
                                  </div>
                                  <div className="w-20 text-right">
                                    <div className="lqd-rst-menu-price pricing font-size-20 font-weight-normal my-0 lh-15">
                                      <span className="price">${menuItem.price}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <h3>Desserts</h3>
                          <div className="row">
                            {starbitesExpressMenu.desserts.map((menuItem, index) => (
                              <div key={index} className="lqd-rst-menu col-md-4">
                                <div className="lqd-rst-menu-inner d-flex flex-wrap">
                                  <div className="w-80">
                                    <h2 className="font-size-16 font-weight-normal mt-0 mb-1">{menuItem.name}</h2>
                                    <p className="reset-ul inline-nav">{menuItem.description}</p>
                                  </div>
                                  <div className="w-20 text-right">
                                    <div className="lqd-rst-menu-price pricing font-size-20 font-weight-normal my-0 lh-15">
                                      <span className="price">${menuItem.price}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  {/* Add similar structure as above */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-30">
          <div className="lqd-column col-md-12">
            <div className="text-center">
              <button className="btn btn btn-default btn-xlg circle btn-bordered border-thin btn-icon-left  font-size-16 font-weight-bold text-uppercase ltr-sp-05 mb-2 p-3" onClick={toggleAdditionalMenu}>
                {showAdditionalMenu ? "Show Less ↑ " : "Show More ↓"}

              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Menu;

// src/components/Popup.js
import React, { useState, useEffect } from 'react';


const Popup = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const hasSeenPopup = localStorage.getItem('hasSeenPopup');
    if (!hasSeenPopup) {
      setIsOpen(true);
      
    }
  }, []);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleOverlayClick = (event) => {
    if (isOpen && event.target.classList.contains('popup-overlay')) {
      handleClose();
    }
  };

  return isOpen ? (
    <div className="popup-overlay" onClick={handleOverlayClick}>
      <div className="popup-content">
        <button className="popup-close text-light font-size-40" onClick={handleClose}>
          x
        </button>
        <img
          className=' '
          src='/assets/img/logo-white.svg'
          alt='...'
         
        />
        <h2 className='text-light font-weight-bold' style={{margin:'0px'}} >Happy Hours</h2>
        <p className='text-light font-weight-bold mt-5 font-size-30'  > Mon to Thurs </p>
        <p className='text-light font-weight-bold mt-3 font-size-30' > 5pm - 8pm </p>
        <p className='text-light mt-2 ' style={{fontSize:'14px'}} > Extended Hours </p>
        <p className='text-light '  style={{fontSize:'14px'}}> 5pm - 10pm, Every Friday  </p>
        <p className='text-light ' style={{fontSize:'14px'}} > </p>
        <p className='text-light mt-5 ' style={{fontSize:'14px'}}>Available at </p>
        <p className='text-light ' style={{fontSize:'14px'}}>East Legon | Airport City | Dansoman| <br /> Westlands | Asokwa </p>
        
        {/* <div className='text-center mt-4'>
          <a href="#" class="btn btn-default btn-sm circle btn-bordered border-thin btn-icon-left  font-size-19 font-weight-bold text-uppercase ltr-sp-05 mb-2" style={{color:'#fff', border: '1px solid #fff'}}>
								<span>
									<span class="btn-txt">Order Now</span>
									<span class="btn-icon"><i class="fa fa-ticket"></i></span>
								</span>
							</a>
          </div> */}
      </div>
    </div>
  ) : null;
};

export default Popup;

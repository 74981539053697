// Tweets From Fam Section

import React, { useEffect, useState } from 'react';
import { TwitterTweetEmbed } from 'react-twitter-embed';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ParallaxSection from '../helpers/ParallaxComponent';
import TwitterEmbedCard from '../helpers/TweetCard';

const TweetsSection = () => {
  const [tweets, setTweets] = useState([]);

  // Replace 'YOUR_PUBLIC_LINK_HERE' with the public link of the Twitter user or hashtag you want to display.
  const publicLink = 'https://twitter.com/StarbitesGh_';

  useEffect(() => {
    // Fetch the tweets from the public link here.
    // If you have a backend, you can make an API call to fetch the tweets using the Twitter API and pass them to the component.
    // For this example, let's assume the tweets are fetched from a mocked API response.

    // Mocked API response containing tweet IDs.
    const mockedApiResponse = {
      tweets: [
        '1686703721126735872',
        '1687069238308077568',
        '1686695319038275584',
        '1687069238308077568',
        '1687051073259810817',
        '1684123336807444480',
        '1682683318381301761',
        // Add more tweet IDs as needed.
      ],
    };

    setTweets(mockedApiResponse.tweets);
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Display three tweets at a time
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1, // Reduce to two tweets on smaller screens (e.g., tablets)
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1, // Reduce to one tweet on mobile screens
        },
      },
    ],
  };

 

  return (

    <div>
      <div className="tweet-carousel tweet-carousel-container ptr-80 ">
        <header className="">
          <h2 className=" mb-5 primary-text header-size text-center font-size-30" style={{ fontFamily: '"Unbounded", sans serif', color:'#E20512', fontWeight:'700', }}>Tweets from fam</h2>
              
        </header>

        <Slider {...settings} >
          
          
          <TwitterEmbedCard style={{padding: '20px'}} className="tweet-wrapper" 
            
            username="MIMII NOKTURNAL 🐉"
             handle="@LoveMimii1"
             content="Starbites is the best now #StarBitesApp "
             likes={73}
             timestamp="11:07 AM · Aug 2, 2023    " // Replace with the actual timestamp
             tweetLink="https://twitter.com/LoveMimii1/status/1686695319038275584"
             avatarUrl="./assets/img/tweet-avatar1.jpg"
          />
          <div style={{margin: '20px'}}>
          <TwitterEmbedCard 
           username="ᴛᴏɴɪᴀ"
           handle="@__liptonia
           "
           content="Order all your delicious meals🍜 from Starbites using the Starbites app.  "
           likes={45}
           timestamp="4:47 PM · Aug 9, 2023" // Replace with the actual timestamp
           tweetLink="https://twitter.com/__liptonia/status/1687061941166981120"
           avatarUrl="./assets/img/tweet-avatar.jpg"
           />
          </div>
          
          <TwitterEmbedCard style={{margin: '20px'}} className="tweet-wrapper" 
            
            username="PeeJay"
             handle="@Blaqboy_CRUYFY"
             content="Starbites has an app now, interesting times ahead."
             likes={4}
             timestamp="11:53 AM · Aug 3, 2023   " // Replace with the actual timestamp
             tweetLink="https://twitter.com/Blaqboy_CRUYFY/status/1687069238308077568"
             avatarUrl="./assets/img/tweet-avatar2.jpg"
          />
          <TwitterEmbedCard style={{margin: '20px'}} className="tweet-wrapper" 
            username="PAPPY KOJO "
            handle="@PAPPYKOJO"
            content="our very own the great sdk is at starbites eating jollof and okra stew "
            likes={274}
            timestamp="6:00 AM · Aug 9, 2023" // Replace with the actual timestamp
            tweetLink="https://twitter.com/PAPPYKOJO/status/1689154764271992832"
            avatarUrl="./assets/img/tweet-avatar3.jpg"
          />
            
        </Slider>
        
      
      </div>
      {/* <ParallaxSection bgImage="./assets/img/chicken2.png" /> */}
      
    </div>

  );
};

export default TweetsSection;
